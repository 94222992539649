import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { TextField, RadioGroup, Radio, FormControlLabel, Select, MenuItem, Button, FormControl, FormLabel, InputLabel } from '@mui/material';
import config from '../config';

const CreateCourse = () => {
  const { user } = useContext(UserContext);
  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [matiere, setMatiere] = useState('');
  const [niveau, setNiveau] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');



  useEffect(() => {
    document.title = "Ajout de cours";
    if (!user || !token) {
      navigate('/connexion'); // Redirect to login if token is not found
      return;
    }
  }, [user, token, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id_utilisateur = user.id
    try {
      const response = await fetch(`${config.apiBaseUrl}/courses`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ titre, description, matiere, niveau, id_utilisateur })

      });
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'An error occurred during signup';
        throw new Error(errorMessage);
      }

      alert('Le cours a été ajouté avec succès.');
      navigate('/mes-cours');
    } catch (error) {
      alert(`erreur: ${error.message}`);
    }
  };



  return (
    <div className="container my-5">
      <h1 className="mb-4">Créer un nouveau cours</h1>
      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Titre"
          type="text"
          value={titre}
          onChange={(e) => setTitre(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Description"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          required
          multiline
          rows={4}
        />
        <FormControl component="fieldset" fullWidth required>
          <FormLabel component="legend">Matière</FormLabel>
          <RadioGroup
            aria-label="matiere"
            name="matiere"
            value={matiere}
            onChange={(e) => setMatiere(e.target.value)}
          >
            <FormControlLabel value="français" control={<Radio />} label="Français" />
            <FormControlLabel value="maths" control={<Radio />} label="Math" />
            <FormControlLabel value="histoire" control={<Radio />} label="Histoire" />
            <FormControlLabel value="géographie" control={<Radio />} label="Géographie" />
            <FormControlLabel value="svt" control={<Radio />} label="SVT" />
            <FormControlLabel value="physique" control={<Radio />} label="Physique" />
            <FormControlLabel value="chimie" control={<Radio />} label="Chimie" />
            <FormControlLabel value="technologie" control={<Radio />} label="Technologie" />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" fullWidth required>
          <FormLabel component="legend">Niveau</FormLabel>
          <RadioGroup
            aria-label="niveau"
            name="niveau"
            value={niveau}
            onChange={(e) => setNiveau(e.target.value)}
          >
            <FormControlLabel value="CM2" control={<Radio />} label="CM2" />
            <FormControlLabel value="6e" control={<Radio />} label="6e" />
            <FormControlLabel value="5e" control={<Radio />} label="5e" />
            <FormControlLabel value="4e" control={<Radio />} label="4e" />
            <FormControlLabel value="3e" control={<Radio />} label="3e" />
          </RadioGroup>
        </FormControl>
        <Button type="submit">
          Soumettre
        </Button>
      </form>
    </div>
  );
};

export default CreateCourse;