import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react'; // Import TinyMCE editor
import config from '../config';
import DOMPurify from 'dompurify';

function sanitizeContent(htmlContent) {
  return DOMPurify.sanitize(htmlContent);
}


const CreateLesson = () => {
  const { user } = useContext(UserContext);
  const { id_cours } = useParams();
  const [titre, setTitre] = useState('');
  const [contenu, setContenu] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    document.title = "Ajout de leçon";
    if (!user || !token) {
      navigate('/connexion'); // Redirect to login page if the user is not logged in
      return;
    }
  }, [user, token, navigate]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!titre.trim()) {
      return alert('Le titre de la leçon ne doit pas être vide.');
    }
    if (!contenu.trim()) {
      return alert('Le contenu de la leçon ne doit pas être vide.');
    }

    try {
      const coursResponse = await fetch(`${config.apiBaseUrl}/courses/${id_cours}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!coursResponse.ok) {
        throw new Error('Failed to fetch course details');
      }

      const cours = await coursResponse.json();

      // Check user permissions
      if (cours.id_formateur !== user.id_utilisateur && user.type !== 'admin') {
        setError('Vous n\'êtes pas autorisé à ajouter une leçon.');
        return;
      }

      const response = await fetch(`${config.apiBaseUrl}/lessons/${id_cours}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ titre, contenu })
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Une erreur s\'est produite lors de l\'ajout de la leçon';
        throw new Error(errorMessage);
      }

      alert('La leçon a été ajoutée avec succès.');
      navigate(`/cours/${id_cours}`);
    } catch (error) {
      alert(`Erreur: ${error.message}`);
      setError(error.message);
    }
  };

  return (
    <div className="container my-5">
      <h1 className="mb-4">Créer une nouvelle leçon</h1>
      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Titre"
          type="text"
          value={titre}
          onChange={(e) => setTitre(e.target.value)}
          fullWidth
          required
        />
        <Editor
          apiKey="c44p1hrf4yc4rvjxbrx3g5tigo8g62fxhng18sfccvldbwvr" // Replace with your TinyMCE API key
          value={contenu}
          onEditorChange={(content) => {
            const sanitizedContent = sanitizeContent(content); // Sanitize here
            setContenu(sanitizedContent);
          }}
          init={{
            height: 400,
            menubar: 'edit insert view format table tools help',
            language: 'fr_FR',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              'lists'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
            block_formats: 'Paragraphe=p; Titre 2=h2; Titre 3=h3; Titre 4=h4; Titre 5=h5; Titre 6=h6;',
            style_formats: [
              {
                title: 'Titres', items: [
                  { title: 'Titre 2', format: 'h2' },
                  { title: 'Titre 3', format: 'h3' },
                  { title: 'Titre 4', format: 'h4' },
                  { title: 'Titre 5', format: 'h5' },
                  { title: 'Titre 6', format: 'h6' }
                ]
              },
              {
                title: 'Inline', items: [
                  { title: 'Gras', format: 'bold' },
                  { title: 'Italique', format: 'italic' },
                  { title: 'Souligné', format: 'underline' },
                  { title: 'Barré', format: 'strikethrough' },
                  { title: 'Exposant', format: 'superscript' },
                  { title: 'Indice', format: 'subscript' },
                  { title: 'Code', format: 'code' }
                ]
              },
              {
                title: 'Blocs', items: [
                  { title: 'Paragraphe', format: 'p' },
                  { title: 'Citation', format: 'blockquote' },
                  { title: 'Div', format: 'div' },
                  { title: 'Préformaté', format: 'pre' }
                ]
              },
              {
                title: 'Alignement', items: [
                  { title: 'Aligner à gauche', format: 'alignleft' },
                  { title: 'Centrer', format: 'aligncenter' },
                  { title: 'Aligner à droite', format: 'alignright' },
                  { title: 'Justifier', format: 'alignjustify' }
                ]
              }
            ]
          }}

        />
        <Button type="submit" variant="contained" color="primary" className="mt-3">
          Soumettre
        </Button>
      </form>
    </div>
  );
};

export default CreateLesson;
