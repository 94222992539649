// src/components/Layout.js
import React from 'react';
import NavMenu from './NavMenu';

const Layout = ({ children }) => {
  return (
    <div>
      <NavMenu />
      <main style={{ padding: '1rem' }}>{children}</main>
    </div>
  );
};

export default Layout;
